<template>
  <div
    class="fixed h-screen w-screen bg-green-100 z-2222 opacity-50 flex items-center justify-center"
  >
    <spinner class="h-20 w-20" color="ansGreen"></spinner>
  </div>
</template>

<script>
import Spinner from "./Spinner.vue";
export default {
  name: "LoadOverlay",
  components: { Spinner },
};
</script>

<style scoped>
.z-2222 {
  z-index: 333;
}
</style>

<template>
  <div
    :class="`w-6 h-6 rounded-full animate-spin border-4 border-solid border-${color} border-t-transparent`"
  ></div>
</template>

<script>
export default {
  name: "Spinner",

  props: {
    color: {
      type: String,
      default: "white",
    },
  },
};
</script>

<style scoped>
.border-t-transparent {
  border-top-color: transparent;
}
</style>

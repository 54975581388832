import axios from "axios";
import Swal from "sweetalert2";

const secureAxios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "http://localhost:8080",
  },
});

secureAxios.interceptors.request.use((config) => {
  if (!config.headers.noauth) {
    const token = localStorage.getItem(process.env.VUE_APP_tokenName);

    if (!token) {
      window.location.href = "/";
      return;
    }

    config.headers["x-access-token"] = token;
  }

  return config;
});

secureAxios.interceptors.response.use(
  (response) => {
    // catch appmart errors here
    // console.log(response);
    if (response.data.code == "00" || response.data.code == "01") {
      return response;
    }

    let message = response.data.message;
    if (response.data.code == "E500") {
      message = "Internal Server error, please try again later";
    }

    Swal.fire({
      title: "Error!",
      text: message,
      icon: "error",
      allowOutsideClick: false,
      confirmButtonColor: "#2FB053",
      cancelButtonColor: "#ff7674",
    });

    if (response.data.code == "9000") {
      setTimeout(() => {
        window.location.href = "/";
      }, 500);
      return;
    }
    return false;
  },
  (err) => {
    console.log({ err });

    if (
      !err.response ||
      err.response.status == 500 ||
      err.response.status == 502
    ) {
      Swal.fire({
        text: "Internal Server Error, if error persist please contact admin",
        icon: "info",
        autoClose: 5000,
        theme: "dark",
      });

      return false;
    }

    // if token is expired, return to the home page
    if (err.response.status == 401 && err.response.data.errorCode == "N403") {
      window.location.href = "/";
      return false;
    }

    const error_message = err.response.data.message
      ? err.response.data.message
      : err.response.data.errors.message;

    Swal.fire({
      title: "Error!",
      text: error_message,
      icon: "error",
      allowOutsideClick: false,
    });

    return false;
  }
);

export default secureAxios;

<template>
  <div id="app">
    <LoadOverlay v-if="$store.state.showOverlay" />
    <router-view />
  </div>
</template>

<script>
import LoadOverlay from "@/components/LoadOverlay";
export default {
  components: { LoadOverlay },
};
</script>

<style></style>

import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    meta: { title: "Login" },
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/Login.vue"),
  },
  {
    path: "/signup",
    name: "signup",
    meta: { title: "Signup" },
    // route level code-splitting
    // this generates a separate chunk (auth.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/SignUp.vue"),
  },
  {
    path: "/verify-account",
    name: "verify-account",
    meta: { title: "Verify account" },
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/VerifyAccount.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    meta: { title: "Forgot password" },
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/ForgotPassword.vue"),
  },
  {
    path: "/update-password",
    name: "update-password",
    meta: { title: "Update password" },
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/UpdatePassword.vue"),
  },
  {
    path: "/app",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/app/AppFrame.vue"),
    children: [
      {
        path: "",
        name: "dashboard",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "../views/app/Dashboard.vue"
          ),
        meta: {
          title: "Dashboard",
          requiresAuth: true,
        },
      },
      {
        path: "/transactions",
        name: "transactions",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "../views/app/Transactions.vue"
          ),
        meta: {
          title: "Transactions",
          requiresAuth: true,
        },
      },
      {
        path: "/transactions/:id",
        name: "view-transaction",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "../views/app/ViewTransaction.vue"
          ),
        props: true,
        meta: {
          title: "Transaction",
          requiresAuth: true,
        },
      },
      {
        path: "/register/unregistered-business",
        name: "register-unregistered-business",
        component: () =>
          import(
            /* webpackChunkName: "business" */ "../views/app/register/UnRegisteredBusiness.vue"
          ),
        props: (route) => ({ stage: route.query.q }),
        meta: {
          title: "Unregistered business",
          requiresAuth: true,
        },
      },
      {
        path: "/register/registered-business",
        name: "register-registered-business",
        component: () =>
          import(
            /* webpackChunkName: "business" */ "../views/app/register/RegisteredBusiness.vue"
          ),
        meta: {
          title: "Rgistered business",
          requiresAuth: true,
        },
      },
      {
        path: "/register/review",
        name: "register-review",
        component: () =>
          import(
            /* webpackChunkName: "business" */ "../views/app/register/RegisterReview.vue"
          ),
        meta: {
          title: "Register review",
          requiresAuth: true,
        },
      },
      {
        path: "/signages",
        name: "signages",
        component: () =>
          import(
            /* webpackChunkName: "business" */ "../views/app/Signages.vue"
          ),
        meta: {
          title: "Signages",
          requiresAuth: true,
        },
      },
      {
        path: "/signages/:id",
        name: "view-signage",
        component: () =>
          import(
            /* webpackChunkName: "business" */ "../views/app/ViewSignage.vue"
          ),
        props: true,
        meta: {
          title: "Signage",
          requiresAuth: true,
        },
      },
      {
        path: "/businesses",
        name: "businesses",
        component: () =>
          import(
            /* webpackChunkName: "business" */ "../views/app/Businesses.vue"
          ),
        meta: {
          title: "Businesses",
          requiresAuth: true,
        },
      },
      {
        path: "/businesses/:id",
        name: "view-business",
        component: () =>
          import(
            /* webpackChunkName: "business" */ "../views/app/ViewBusiness.vue"
          ),
        props: true,
        meta: {
          title: "Business",
          requiresAuth: true,
        },
      },
      {
        path: "/invoices",
        name: "invoices",
        component: () =>
          import(
            /* webpackChunkName: "business" */ "../views/app/invoice/Invoices.vue"
          ),
        meta: {
          title: "Invoices",
          requiresAuth: true,
        },
      },
      {
        path: "/invoice/:id",
        name: "invoice",
        component: () =>
          import(
            /* webpackChunkName: "business" */ "../views/app/invoice/Invoice.vue"
          ),
        props: true,
        meta: {
          title: "Invoice",
          requiresAuth: true,
        },
      },
      {
        path: "/profile",
        name: "profile",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "../views/app/Profile.vue"
          ),
        props: true,
        meta: {
          title: "Profile",
          requiresAuth: true,
        },
      },
      {
        path: "/request-business-linkage",
        name: "request-business-linkage",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "../views/app/RequestBusinessLinkage.vue"
          ),
        props: true,
        meta: {
          title: "Request business linkage",
          requiresAuth: true,
        },
      },
      {
        path: "/business-linkages",
        name: "business-linkages",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "../views/app/BusinessLinkages.vue"
          ),
        props: true,
        meta: {
          title: "Business linkages",
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    meta: {
      guest: true,
    },
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/PageNotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const title = to.meta.title;
  // If the route has a title, set it as the page title of the document/page
  if (title) {
    document.title = "ANSAA | " + title;
  }

  if (to.meta.guest || !to.meta.requiresAuth) {
    next();
    return;
  }

  if (!localStorage.getItem(process.env.VUE_APP_tokenName)) {
    //also check if the token has expired
    next({
      path: "/",
      query: { nextUrl: to.fullPath },
    });
    return;
  }

  next();
  return;
});

export default router;

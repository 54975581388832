import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });
import {
  fetchAreas,
  fetchCities,
  fetchStreets,
  fetchSignCategories,
  fetchSignTypes,
  fetchSignSubs,
  fetchSignItems,
  fetchSignSubitems,
  fetchProfile,
  fetchBusinessTypes,
  fetchBusinessCategories,
} from "../services/sourceData";

Vue.use(Vuex);

const intialState = {
  user: null,
  profile: null,
  signageModal: false,
  menuModal: false,
  businesses: [],
  newRegister: {},
  lgas: [],
  cities: [],
  areas: [],
  streets: [],
  businessTypes: [],
  businessCategories: [],
  signCategories: [],
  signTypes: [],
  signItems: [],
  signSubitems: [],
  signSubscriptions: [],
  showOverlay: false,
};

export default new Vuex.Store({
  plugins: [
    // persists the data on page reload
    createPersistedState({
      key: process.env.VUE_APP_appDB,
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],

  state: {
    ...intialState,
  },
  getters: {
    userId: (state) => {
      return state.user ? state.user.id : null;
    },
    userEmail: (state) => {
      return state.user ? state.user.email : null;
    },
    userNin: (state) => {
      return state.profile ? state.profile.nin : null;
    },
    ninSet: (state) => {
      if (state.profile && state.profile.nin && state.profile.virtual_nin) {
        return 2;
      } else if (state.profile && state.profile.nin) {
        return 1;
      } else {
        return 0;
      }
    },
    userPhone: (state) => {
      return state.user ? state.user.phone : null;
    },
    userLogo: (state) => {
      return state.profile && state.profile.user && state.profile.user.picture
        ? state.profile.user.picture
        : null;
    },
    signageModal: (state) => {
      return state.signageModal;
    },
    menuModal: (state) => {
      return state.menuModal;
    },
    businessCount: (state) => {
      return state.businesses.length;
    },
    allBusinesses: (state) => {
      return state.businesses;
    },
    towns: (state) => (lga) => {
      return state.cities
        .filter((city) => city.local_govt == lga)
        .map((city) => {
          return {
            label: city.city,
            value: city.idcities,
          };
        });
    },
    busType: (state) => (id) => {
      return state.businessTypes.find((cat) => cat.value == id);
    },
    busCategory: (state) => (id) => {
      return state.businessCategories.find((cat) => cat.value == id);
    },
    signCategory: (state) => (id) => {
      return state.signCategories.find((cat) => cat.value == id);
    },
    signType: (state) => (id) => {
      return state.signTypes.find((cat) => cat.value == id);
    },
    signItem: (state) => (id) => {
      return state.signItems.find((cat) => cat.value == id);
    },
    town: (state) => (id) => {
      return state.cities.find((cat) => cat.idcities == id);
    },
    area: (state) => (id) => {
      return state.areas.find((cat) => cat.value == id);
    },
    street: (state) => (id) => {
      return state.streets.find((cat) => cat.value == id);
    },
  },
  mutations: {
    toggleOverlay(state) {
      state.showOverlay = !state.showOverlay;
    },
    saveUser(state, user) {
      state.user = user;
    },
    saveBusinesses(state, businesses) {
      state.businesses = businesses;
    },
    saveLgas(state, lgas) {
      state.lgas = lgas;
    },
    saveCities(state, cities) {
      state.cities = cities;
    },
    saveBusinessCategories(state, data) {
      state.businessCategories = data.map((dt) => {
        return {
          label: dt.company_category,
          value: dt.idcompany_categories,
        };
      });
    },
    saveBusinessTypes(state, data) {
      state.businessTypes = data.map((dt) => {
        return {
          label: dt.name,
          value: dt.id,
        };
      });
    },
    saveAreas(state, areas) {
      state.areas = areas.map((area) => {
        return {
          label: area.area_name,
          value: area.id,
        };
      });
    },
    saveStreets(state, streets) {
      state.streets = streets.map((street) => {
        return {
          label: street.street_name,
          value: street.id,
        };
      });
    },
    saveSignCategories(state, signCategories) {
      state.signCategories = signCategories.map((cat) => {
        return {
          label: cat.category_name,
          value: cat.id,
          is_firstparty: cat.is_firstparty,
        };
      });
    },
    saveSignTypes(state, signTypes) {
      state.signTypes = signTypes.map((typ) => {
        return {
          label: typ.name,
          value: typ.id,
        };
      });
    },
    saveSignItems(state, signItems) {
      state.signItems = signItems.map((typ) => {
        return {
          label: typ.item_name,
          value: typ.id,
          has_subitems: typ.has_subitems,
        };
      });
    },
    saveSignSubitems(state, signSubitems) {
      state.signSubitems = signSubitems.map((typ) => {
        return {
          label: typ.subitem_name,
          value: typ.id,
        };
      });
    },
    saveSignSubs(state, signSubscriptions) {
      state.signSubscriptions = signSubscriptions.map((typ) => {
        return {
          label: typ.subscription_name,
          value: typ.id,
          no_of_days: typ.no_of_days,
          subscription_cost: typ.subscription_cost,
          consideration_name: typ.consideration_name,
          is_percentage: typ.is_percentage,
          consideration_amount: typ.consideration_amount,
        };
      });
    },
    wipeLocations(state) {
      state.towns = [];
      state.areas = [];
      state.streets = [];
    },
    wipeSignOptions(state) {
      state.signTypes = [];
      state.signItems = [];
      state.signSubitems = [];
      state.signSubscriptions = [];
    },
    wipeRegister(state) {
      state.newRegister = {};
    },
    toggleSignageModal(state) {
      state.signageModal = !state.signageModal;
    },
    toggleMenuModal(state) {
      state.menuModal = !state.menuModal;
    },
    addTonewRegister(state, data) {
      state.newRegister = { ...state.newRegister, ...data };
    },
    clearRegister(state) {
      state.newRegister = {};
    },
    clearState(state) {
      Object.keys(state).forEach((key) => {
        if (Array.isArray(state[key])) {
          state[key] = [];
        } else {
          state[key] = false;
        }
      });
    },
    saveProfile(state, profile) {
      state.profile = profile;
    },
    updateProfile(state, data) {
      state.profile = { ...state.profile, ...data };
    },
  },
  actions: {
    saveUserInfo({ commit }, user) {
      if (user.token)
        localStorage.setItem(process.env.VUE_APP_tokenName, user.token);

      commit("saveUser", user);
    },

    async getTownLga({ commit }) {
      const cities = await fetchCities();

      commit("saveCities", cities);

      const lgas = cities.filter(
        (value, index, self) =>
          index == self.findIndex((t) => t.local_govt == value.local_govt)
      );

      commit(
        "saveLgas",
        lgas.map((l) => {
          return {
            label: l.local_govt,
            value: l.local_govt,
          };
        })
      );
    },

    async getAreas({ commit }, townId) {
      const areas = await fetchAreas(townId);

      commit("saveAreas", areas);
    },

    async getStreets({ commit }, areaId) {
      const streets = await fetchStreets(areaId);

      commit("saveStreets", streets);
    },

    async getSignCategories({ commit }) {
      const categories = await fetchSignCategories();

      commit("saveSignCategories", categories);
    },

    async getSignTypes({ commit }, categoryId) {
      const types = await fetchSignTypes(categoryId);

      commit("saveSignTypes", types);
    },

    async getSignItems({ commit }, categoryId) {
      const data = await fetchSignItems(categoryId);

      commit("saveSignItems", data);
    },

    async getSignSubitems({ commit }, itemId) {
      const data = await fetchSignSubitems(itemId);

      commit("saveSignSubitems", data);
    },

    async getProfile({ commit, state }) {
      const data = await fetchProfile(state.user.id);

      commit("saveProfile", data);
    },

    async getsignSubs({ commit }, { categoryId, itemId, typeId }) {
      const data = await fetchSignSubs({
        categoryId,
        itemId,
        typeId,
      });

      commit("saveSignSubs", data);
    },

    logout({ commit }) {
      localStorage.removeItem(process.env.VUE_APP_tokenName);

      commit("clearState");
      localStorage.removeItem(process.env.VUE_APP_appDB);
    },

    async getBusinessCategories({ commit, state }) {
      if (state.businessCategories.length) return;

      const businessCategories = await fetchBusinessCategories();

      commit("saveBusinessCategories", businessCategories);
    },

    async getBusinessTypes({ commit, state }) {
      if (state.businessTypes.length) return;

      const businessTypes = await fetchBusinessTypes();

      commit("saveBusinessTypes", businessTypes);
    },
  },
  modules: {},
});

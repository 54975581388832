import secureAxios from "./axiosInstance";

export const fetchCities = async () => {
  const res = await secureAxios.post("/get-cities");
  return res ? res.data.message : [];
};
export const fetchAreas = async (townId) => {
  const res = await secureAxios.post("/get-areas", { town_id: townId });
  return res ? res.data.message : [];
};
export const fetchStreets = async (areaId) => {
  const res = await secureAxios.post("/get-street", { area_id: areaId });
  return res ? res.data.message : [];
};
export const fetchSignCategories = async () => {
  const res = await secureAxios.post("/get-category");
  return res ? res.data.message : [];
};
export const fetchSignTypes = async (categoryId) => {
  const res = await secureAxios.post("/get-signage-type", {
    category_id: categoryId,
  });
  return res ? res.data.message : [];
};
export const fetchSignItems = async (categoryId) => {
  const res = await secureAxios.post("/get-items", {
    category_id: categoryId,
  });
  return res ? res.data.message : [];
};
export const fetchSignSubitems = async (itemId) => {
  const res = await secureAxios.post("/get-subitems", {
    item_id: itemId,
  });
  return res ? res.data.message : [];
};
export const fetchSignSubs = async ({ categoryId, itemId, typeId }) => {
  const res = await secureAxios.post("/get-subscription-types", {
    category_id: categoryId,
    type_id: typeId,
    item_id: itemId,
  });
  return res ? res.data.message : [];
};
export const fetchProfile = async (userId) => {
  const res = await secureAxios.post("/get-profile", {
    userId: userId,
  });
  return res ? res.data.message : null;
};
export const fetchBusinessCategories = async () => {
  const res = await secureAxios.post("/get-business-category");
  return res ? res.data.message : [];
};
export const fetchBusinessTypes = async () => {
  const res = await secureAxios.post("/get-business-types");
  return res ? res.data.message : [];
};
